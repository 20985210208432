import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadingMaskService } from '../loading-mask.service';

export interface ILoadingContext {
  skipLoading: boolean;
  message: string | null;
  skipHiding: boolean;
}

export const LoadingOptions = new HttpContextToken<ILoadingContext>(() => { return { skipLoading: false, message: null, skipHiding: false } as ILoadingContext });

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingMaskService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const context = request.context.get(LoadingOptions);
    const isIdPortenConfiguration = request.url.endsWith('.well-known/openid-configuration') || request.url.endsWith("jwks.json");
    if (context.skipLoading || isIdPortenConfiguration) {
      return next.handle(request);
    }

    this.loadingService.showLoadingMask(context.message);

    return next.handle(request).pipe(
      finalize(() => {
        if (context.skipHiding !== true) {
          this.loadingService.turnOffLoadingMask();
        }
      })
    );
  }
}
