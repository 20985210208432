import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider, NgModule } from '@angular/core';
import { RouterModule, /* Routes */ } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LandingPageAwaitingSignatureComponent } from './components/landing-page/landing-page-awaiting-signature/landing-page-awaiting-signature.component';
import { LandingPageErrorComponent } from './components/landing-page/landing-page-error/landing-page-error.component';
import { LandingPageCancelledComponent } from './components/landing-page/landing-page-cancelled/landing-page-cancelled.component';
import { LandingPageQueuedComponent } from './components/landing-page/landing-page-queued/landing-page-queued.component';
import { LandingPageConsentObtainedComponent } from './components/landing-page/landing-page-consent-obtained/landing-page-consent-obtained.component';
import { LandingPageRejectedComponent } from './components/landing-page/landing-page-rejected/landing-page-rejected.component';
import { LandingPageSentComponent } from './components/landing-page/landing-page-sent/landing-page-sent.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LandingPageDetailsComponent } from './components/landing-page-details/landing-page-details.component';
import { LandingPageReceiptComponent } from './components/landing-page-receipt/landing-page-receipt.component';
import { ProgressLineComponent } from './components/progress-line/progress-line.component';
import { LandingPageMainContainerComponent } from './components/landing-page-main-container/landing-page-main-container.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpInterceptorProviders } from './providers/http-interceptor-providers';
import { FormatAddressPipe } from './services/Pipes/format-address.pipe';
import { FormatFilesizePipe } from './services/Pipes/format-filesize.pipe';
import { FormatHoldingNumberPipe } from './services/Pipes/format-holding-number.pipe';
import { LoadingMaskComponent } from './components/loading-mask/loading-mask.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthRedirectionGuard } from './guards/redirection-handler.guard';
import { logoutHandlerGuard } from './guards/logout-handler.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { postLogoutEndpoint } from './auth-config';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PdfViewModalComponent } from './components/pdf-view-modal/pdf-view-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LandingPageMainPageHeaderComponent } from './components/landing-page/landing-page-main-page-header/landing-page-main-page-header.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LandingPageDetailsComponent,
    LandingPageReceiptComponent,
    LandingPageAwaitingSignatureComponent,
    LandingPageErrorComponent,
    LandingPageCancelledComponent,
    LandingPageQueuedComponent,
    LandingPageConsentObtainedComponent,
    LandingPageRejectedComponent,
    LandingPageSentComponent,
    ProgressLineComponent,
    LandingPageMainContainerComponent,
    LandingPageMainPageHeaderComponent,
    FormatAddressPipe,
    FormatFilesizePipe,
    FormatHoldingNumberPipe,
    LoadingMaskComponent,
    UnauthorizedComponent,
    PdfViewModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true,
      }
    }),
    RouterModule.forRoot([
      { path: '', canActivate: [AuthRedirectionGuard], component: NotFoundComponent }, //this component should never be loaded. guard will redirect to correct page and handle state
      { path: `${postLogoutEndpoint}`, canActivate: [logoutHandlerGuard], component: NotFoundComponent }, //this component should never be loaded. guard will redirect to correct page and handle state
      { path: 'application', component: LandingPageMainContainerComponent },
      { path: '**', component: NotFoundComponent }
    ]),
    NgbModule,
    ModalModule.forRoot(),
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    LandingPageDetailsComponent,
    LandingPageReceiptComponent,
    LandingPageAwaitingSignatureComponent,
    LandingPageErrorComponent,
    LandingPageCancelledComponent,
    LandingPageQueuedComponent,
    LandingPageConsentObtainedComponent,
    LandingPageRejectedComponent,
    LandingPageSentComponent,
    LandingPageMainPageHeaderComponent,
    ProgressLineComponent,
    LandingPageMainContainerComponent,
    PdfViewModalComponent,
  ],
  providers: [
    HttpInterceptorProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
