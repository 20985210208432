<div>
  <progress-line [step]="3"></progress-line>
  <div class="landing-page-container">
    <div class="header-container">
      Du har nå sendt inn søknaden
    </div>
    <div class="content-container">
      Byggesøknaden er sendt til kommunen.
      Vær likevel oppmerksom på at det kan ta noe tid før den blir registrert som levert da det kan være mange og store filer som skal leveres.
      Søknaden blir lagret i ditt arkiv i Altinn, med referanse {{arCode$ | async}}.
      Trykk her for å se arkivet ditt i Altinn
      <a href="{{archiveLink$ | async}}">{{archiveLink$ | async}}</a>
    </div>
    <div class="content-container">
      <span>Ønsker du kvittering sendt på e-post?</span>
      <span class="email-container ">
        <input class="email-input" type="email" [value]="email" (input)="onEmailChange($event)" /> &nbsp;
        <button [disabled]="!isValid" class="btn btn-primary" (click)="sendEmailReceipt()">Send meg kvittering</button>
      </span>
    </div>
    <button class="btn btn-default btn-large-font" (click)="logoutFromAltinn()">Logg ut</button>
  </div>
</div>
