import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../models/altinn-application/address';

@Pipe({
  name: 'formatHoldingNumber'
})
export class FormatHoldingNumberPipe implements PipeTransform {

  transform(value: Address): string {
    const gnr = this.sanitize(value.gnr);
    const bnr = this.sanitize(value.bnr);
    const snr = this.sanitize(value.snr);
    const fnr = this.sanitize(value.fnr);
    return `(${gnr}/${bnr}/${fnr}/${snr})`;
  }

  private sanitize(value: string): string {
    return value == null || value.trim() === '' ? '0' : value;
  }
}
