import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../models/altinn-application/address';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {

  transform(value: Address): string {
    const streetNumber = value.streetNumber ? ` ${value.streetNumber}` : '';
    const addresLine2 = value.addresLine2 ? `, ${value.addresLine2}` : ''
    const city = value.city
      ? value.zipCode ? `, ${value.zipCode} ${value.city}` : `, ${value.city}`
      : '';
    return `${value.streetName}${streetNumber}${addresLine2}${city}`;
  }

}
