import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// if we move to redirection based flow
// instead of popup add fields here
// to handle that redirect
export interface IRedirectionState {
  applicationId: string;
  authorizedToSignApplication: boolean;
  initialLogin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RedirectionStateParserService {

  generateStateString(applicationId: string, authorizedToSignApplication: boolean, initialLogin: boolean) {
    const state = {
      applicationId: applicationId,
      authorizedToSignApplication: authorizedToSignApplication,
      initialLogin: initialLogin,
    } as IRedirectionState
    return this.base64Encode(state);
  }

  decodeStateString(state: string) {
    return this.base64Decode(state);
  }

  private base64Encode(state: IRedirectionState) {
    const jsonState = JSON.stringify(state);
    const encoded = btoa(jsonState);
    return encoded;
  }

  private base64Decode(encodedState: string) {
    const decoded = atob(encodedState);
    return JSON.parse(decoded) as IRedirectionState;
  }
}
