import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../models/altinn-application/altinn-application';
import { BackendApplicationService } from '../../services/backend/backend-application.service';
import { ApplicationAttachment } from '../../models/altinn-application/application-attachment';
import { openAttachmentInNewWindow } from '../../services/helper-functions';
import { NavigationService } from '../../services/navigation.service';
import { LandingPageStateService } from '../../services/landing-page-state.service';
import { AltinnApplicationService, IFormSubmissionResult } from '../../services/altinn/altinn-application.service';
import { AuthorizationService } from '../../services/authorization.service';
import { filter, switchMap, tap } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PdfViewModalComponent } from '../pdf-view-modal/pdf-view-modal.component';

@Component({
  selector: 'landing-page-details',
  templateUrl: './landing-page-details.component.html',
  styleUrls: ['./landing-page-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageDetailsComponent implements OnInit {

  @Input() application!: AltinnApplication;

  isAccepted?: boolean;
  rejectionReason: string = '';
  rejectionCount = 0;

  constructor(
    private readonly backendApplicationService: BackendApplicationService,
    private readonly navigationService: NavigationService,
    private readonly landingPageStateService: LandingPageStateService,
    private readonly altinnApplicationService: AltinnApplicationService,
    private readonly authorizationService: AuthorizationService,
    private readonly modalService: BsModalService,
  ) {
  }

  ngOnInit(): void {
    this.navigationService.isPostRedirect$.pipe(
      filter(isRedirect => isRedirect === true),
      tap(_ => this.isAccepted = true),
      switchMap(_ => this.altinnApplicationService.sendAltinnForm(this.application))
    ).subscribe(result => {
      this.handleAcception(result as IFormSubmissionResult);
    })
  }

  get attachments() {
    return this.application?.attachments ?? [];
  }

  changeAccepted(accept: boolean) {
    this.isAccepted = accept ? !(this.isAccepted ?? false) : !(this.isAccepted ?? true);
  }

  returnToFirstStep() {
    this.navigationService.toStart();
  }

  accept() {
    this.authorizationService.logIn(this.application.applicationID);
  }

  reject() {
    this.backendApplicationService.rejectApplication(this.application.applicationID, this.rejectionReason).subscribe(_ => {
      this.landingPageStateService.fetchApplicationByID(this.application.applicationID)
      this.navigationService.toStart();
    });
  }

  previewAttachment(attachment: ApplicationAttachment) {
    const options: ModalOptions<PdfViewModalComponent> = {
      initialState: {
        attachment: attachment,
      },
      class: 'modal-lg'
    }
    const modalRef = this.modalService.show(PdfViewModalComponent, options)
  }

  onRejectionReasonChange(event: Event): void {
    const value = (event.target as any).value;
    this.rejectionReason = value;
    this.rejectionCount = this.rejectionReason.length;
  }

  canReject() {
    return this.rejectionCount <= 500 && this.rejectionCount > 0 && this.rejectionReason.trim().length > 0;
  }

  downloadAttachment(item: ApplicationAttachment) {
    this.backendApplicationService.fetchAttachment(this.application.applicationID, item.blobReferenceId).subscribe(response => {
      openAttachmentInNewWindow(response, item.filename!);
    });
  }

  isPdf(attachment: ApplicationAttachment) {
    return attachment.filename.toLowerCase().endsWith("pdf")
  }

  private handleAcception(sendingResult: IFormSubmissionResult) {
    if (sendingResult?.success === true) {
      this.landingPageStateService.updateAltinnApplionReference(sendingResult.arCode, sendingResult.messageId);
      this.navigationService.toReceipt();
    } else if (sendingResult?.unauthorizedInAltinn === true) {
      this.navigationService.toError();
    }
  }
}
