import { Injectable } from '@angular/core';
import { IAltinnSubmission, IBlobFile, IVedlegg } from '../../models/altinn-application/altinn-request-form';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { concatMap, from, map, mergeMap, toArray } from 'rxjs';
import { APPLICATION_ID_CONTEXT, IS_LOGGING_ENABLED, PROCESS_ID_CONTEXT } from '../interceptors/altinn-request.interceptor';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AltinnRequestService {

  private readonly contentTypeHALJSON = "application/hal+json";
  private readonly contentTypeOctetStream = "application/octet-stream";
  constructor(
    private readonly httpClient: HttpClient,
    private readonly oauthService: OAuthService,
  ) {

  }

  getProfile() {
    const getURL = `${environment.altinnBaseUrl}api/my/profile`;

    return this.httpClient.get<any>(getURL, {
      headers: this.defaultAltinnRequestHeaders,
      observe: 'response',
    });
  }

  submitApplication(messageBox: string, submission: IAltinnSubmission, applicationId: string, processId: string) {
    const postURL = `${environment.altinnBaseUrl}api/${messageBox}/messages`;
    const context = new HttpContext();
    this.addLoggingToContext(context, applicationId, processId);
    return this.httpClient.post<any>(postURL, submission, {
      headers: this.defaultAltinnRequestHeaders,
      observe: 'response',
      params: {
        complete: false,
      },
      context: context,
    }).pipe(

    );
  }

  submitFinalPackage(
    orgNo: string,
    messageId: string,
    serviceCode: number,
    serviceEditionCode: number,
    applicationId: string,
    processId: string) {

    const putURL = `${environment.altinnBaseUrl}api/${orgNo}/messages/${messageId}`;
    const context = new HttpContext();
    this.addLoggingToContext(context, applicationId, processId);
    const data = {
      Type: "FormTask",
      ServiceCode: serviceCode,
      ServiceEdition: serviceEditionCode,
    };

    return this.httpClient.put<any>(putURL, data, {
      headers: this.defaultAltinnRequestHeaders,
      observe: 'response',
      params: {
        language: 1044,
        complete: true,
        sign: true
      },
      context: context,
    }).pipe(
    );
  }

  getSubmittedApplication(messageBox: string, finalMessageId: string) {
    const getURL = `${environment.altinnBaseUrl}api/${messageBox}/messages/${finalMessageId}`;
    return this.httpClient.get<any>(getURL, { headers: this.defaultAltinnRequestHeaders });
  }

  submitAttachments(attachments: IVedlegg[], messageBox: string, messageId: string) {
    const postURL = `${environment.altinnBaseUrl}api/${messageBox}/messages/${messageId}/attachments/streamedattachment`;
    const headers = this.buildAltinnRequestHeaders(this.contentTypeOctetStream);

    const attachmentToRequestMap = (attachment: IVedlegg) => {
      const blob = new Blob([this.base64ToArrayBuffer(attachment.data)]);
      return this.httpClient.post(postURL, blob, {
        headers: headers,
        params:
        {
          filename: attachment.fileName,
          attachmenttype: attachment.attachmentType
        }
      });
    };

    attachments = attachments == null ? [] : attachments;
    return from(attachments).pipe(
      mergeMap(attachmentToRequestMap),
      toArray(),
    );
  }


  fetchFullApplicationPDF(messageBox: string, messageId: string) {
    const pdfUrl = `${environment.altinnBaseUrl}api/${messageBox}/messages/${messageId}/print`;
    return this.httpClient.get<ArrayBuffer>(pdfUrl, {
      headers: this.defaultAltinnRequestHeaders,
      responseType: 'arrayBuffer' as 'json',
    }).pipe(
      map(blob => {
        return {
          filename: `${messageId}.pdf`,
          formName: messageId,
          isMergedPdf: true,
          content: blob,
          size: blob.byteLength
        } as IBlobFile
      }));
  }

  fetchIndividualApplicationAttachments(messageBox: string, messageId: string) {
    const formUrl = `${environment.altinnBaseUrl}api/${messageBox}/messages/${messageId}/forms`;

    let requests = this.httpClient.get<IAltinnSubmission>(formUrl, { headers: this.defaultAltinnRequestHeaders }).pipe(
      map(submission => submission._embedded.forms),
      concatMap(forms => from(forms)),
      concatMap(form => {
        const printUrl = `${formUrl}/${form.FormId}/print`;
        if (!form.Name) {
          throw Error("Misformatted Attachment in Altinn");
        }

        return this.httpClient.get<ArrayBuffer>(printUrl, {
          headers: this.defaultAltinnRequestHeaders,
          responseType: 'arrayBuffer' as 'json',
        }).pipe(
          map(blob => {
            return {
              formName: form.Name,
              filename: this.makeFileNameOutOfFormName(form.Name!),
              content: blob,
              size: blob.byteLength,
              isMergedPdf: false,
            } as IBlobFile
          })
        )
      })
    );
    return requests;
  }

  deleteMessage(messageBox: string, messageId: string, applicationId: string, processId: string) {
    const deleteURL = `${environment.altinnBaseUrl}api/${messageBox}/messages/${messageId}`;
    const context = new HttpContext();
    this.addLoggingToContext(context, applicationId, processId);
    return this.httpClient.delete(deleteURL, {
      headers: this.defaultAltinnRequestHeaders,
      context: context,
    });
  }

  private get defaultAltinnRequestHeaders() {
    return this.buildAltinnRequestHeaders(this.contentTypeHALJSON);
  }

  private buildAltinnRequestHeaders(contentType: string) {
    const headers = new HttpHeaders({
      Accept: this.contentTypeHALJSON,
      ApiKey: environment.altinnApiKey,
      Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
      'Content-Type': contentType,
    });
    return headers;
  }

  private makeFileNameOutOfFormName(formName: string) {
    return formName.split(",")[0] + ".pdf";
  }

  private base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private addLoggingToContext(context: HttpContext, applicationId: string, processId: string) {
    context.set(IS_LOGGING_ENABLED, true);
    context.set(APPLICATION_ID_CONTEXT, applicationId);
    context.set(PROCESS_ID_CONTEXT, processId);
  }
}
