<div *ngIf="application; then content else loading"></div>

<ng-template #loading>
</ng-template>
<ng-template #content></ng-template>
<progress-line [step]="2"></progress-line>
<div class="landing-page-container">
  <div class="header-container">
    Godkjenn og send søknaden
  </div>
  <div class="content-container project-details">
    <span>
      <span class="details-field-name">Tiltakshaver: &nbsp;</span>
      <span>{{application.developerName}}</span>
    </span>
    <span>
      <span class="details-field-name">Adresse: &nbsp;</span>
      <span>{{application.address | formatAddress}} {{application.address | formatHoldingNumber}}</span>
    </span>
    <span>
      <span class="details-field-name">Planlagt tiltak: &nbsp;</span>
      <span>{{application.plannedAction}}</span>
    </span>
  </div>
  <div class="content-container attachments-container">
    <span>SØKNAD MED VEDLEGG</span>
    <span>Forsikre deg om at du leser alle dokumentene</span>
    <table class="table table-striped table-hover">
      <thead class="table-header table-light table-header">
        <tr>
          <th class="" scope="col">Vedleggstype</th>
          <th class="" scope="col">Beskrivelse/tittel</th>
          <th class="" scope="col">Filnavn</th>
          <th class="" scope="col">Filstørrelse</th>
          <th class="" scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of attachments; index as i;" class="attachments-table">
          <tr>
            <td>{{item.attachmentTypeDescription}}</td>
            <td>{{item.description}}</td>
            <td>{{item.filename}}</td>
            <td>{{item.filesize | formatFilesize}}</td>
            <td><button class="btn btn-default table-button" (click)="isPdf(item) ? previewAttachment(item) : downloadAttachment(item)"> {{isPdf(item)? "Vis" : "Last ned"}}</button></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="content-container">
    <div class="checkbox-with-label">
      <span style="margin-bottom: 10px;">
        <input type="checkbox" [checked]="isAccepted === true" (change)="changeAccepted(true)" /><span>&nbsp; Godkjenner søknaden</span>
      </span>
      <span>
        Jeg bekrefter med dette å ha lest gjennom søknaden med vedlegg og at jeg godkjenner innholdet. Søknaden og alle relevante dokumenter er skrevet eller oversatt til norsk, svensk eller dansk.
        Jeg er også mitt ansvar bevisst i byggesaken.
      </span>
    </div>
    <div class="checkbox-with-label">
      <span style="margin-bottom: 10px;">
        <input type="checkbox" [checked]="isAccepted === false" (change)="changeAccepted(false)" /><span>&nbsp;Jeg avviser søknaden</span>
      </span>
      <span>Jeg har oppdaget feil eller mangler ved søknaden og godkjenner derfor ikke innholdet.</span>
    </div>
  </div>
  <div *ngIf="isAccepted === true">
    <div class="content-container">
      <span>SEND SØKNADEN TIL KOMMUNEN</span>
      <span>Når du trykker SEND SØKNAD, signeres byggesøknaden og sendes kommunen for behandling. Dersom du signerer som privatperson, blir fødselsnummeret ditt brukt i kommunens saksbehandlingssystem.</span>
      <div *ngIf="application.sendingDisabled" class="alert alert-warning">
        <div class="flex-row alert-no-margin">
          <div class="altinn-chapter-line-validation--text-and-icon">
            <span>
              <span class="glyphicon glyphicon-exclamation-sign"></span>
              {{application.sendingDisabledMessage}}
            </span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-large-font constrain-size" [disabled]="application.sendingDisabled" (click)="accept()">Send søknad</button>
    </div>
  </div>
  <div *ngIf="isAccepted === false">
    <div class="content-container">
      <span>RAPPORTER FEIL OG MANGLER</span>
      <span>
        Når du avviser søknaden, vil den som utarbeidet søknaden få beskjed slik at søknaden kan suppleres med mangler og feil kan rettes. Søknaden vil så kunne sendes på nytt til deg for godkjenning og signering.
      </span>
      <span>
        Beskriv her hvorfor du ønsker å avvise søknaden, med hvilke feil og mangler du mener søknaden har
      </span>
      <textarea name="" rows="10" [value]="rejectionReason" (input)="onRejectionReasonChange($event)"></textarea>
      <span class="text-end textbox-count" [ngClass]="{'text-danger': rejectionCount > 500}"> {{rejectionCount}}/500 </span>
      <button [disabled]="!canReject()" class="btn btn-primary btn-large-font constrain-size " (click)="canReject() && reject()">Avvis søknad</button>
    </div>
  </div>
  <button class="btn btn-default btn-large-font" (click)="returnToFirstStep()">Tilbake</button>
</div>
