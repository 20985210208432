<div class="bar">
  &nbsp;
</div>
<div class="progress-bar-steplist">
  <div class="step">
    <div class="progress-counter" [ngClass]="{'active': step === 1}">
      <span>1</span>
    </div>
    <div class="progress-label">
      Tiltak uten ansvarsrett
    </div>
  </div>
  <div class="step">
    <div class="progress-counter" [ngClass]="{'active': step=== 2}">
      <span>2</span>
    </div>
    <div class="progress-label">
      Godkjenn og send søknaden
    </div>
  </div>
  <div class="step">
    <div class="progress-counter" [ngClass]="{'active': step === 3}">
      <span>3</span>
    </div>
    <div class="progress-label">
      Kvittering
    </div>
  </div>
</div>
