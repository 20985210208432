export enum ApplicationStatus {
  Active = 0,
  Canceled = 1,
  Sent = 2,
  Queued = 3,
  Error = 4,
  WaitingForDeveloperConsent = 5,
  DeveloperConsentObtained = 6,
  RequestForDeveloperConsentQueued = 7,
  AwaitingSignatureFromDeveloper = 8,
  RejectedByDeveloper = 9,
}
