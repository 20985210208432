import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment.bat';
import { BackendApplicationService } from '../backend/backend-application.service';


export const IS_LOGGING_ENABLED = new HttpContextToken<boolean>(() => false);
export const APPLICATION_ID_CONTEXT = new HttpContextToken<string>(() => '');
export const PROCESS_ID_CONTEXT = new HttpContextToken<string>(() => '');

@Injectable()
export class AltinnRequestInterceptor implements HttpInterceptor {

  constructor(
    private readonly backendApplicationService: BackendApplicationService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        response => {
          //Log only application creation and finalization, and ignore attachments
          if (request.context.get(IS_LOGGING_ENABLED) && response instanceof HttpResponse) {
            this.logRequest(request, response);
          }
        },
        err => {
          if (request.context.get(IS_LOGGING_ENABLED) && err instanceof HttpErrorResponse) {
            this.logError(request, err);
          }
        },
      ),
    );
  }


  private logRequest(request: HttpRequest<unknown>, response: HttpResponse<any>) {
    const applicationId = request.context.get(APPLICATION_ID_CONTEXT);
    const processId = request.context.get(APPLICATION_ID_CONTEXT);
    let requestMessage = JSON.stringify(request);
    let responseMessage = JSON.stringify(response);
    let statusCode = response.status;
    let isError = false

    this.backendApplicationService.logRequest(applicationId, processId, requestMessage, responseMessage, statusCode, request.urlWithParams, request.method, isError).subscribe();

  }

  private logError(request: HttpRequest<unknown>, err: HttpErrorResponse) {
    const applicationId = request.context.get(APPLICATION_ID_CONTEXT);
    const processId = request.context.get(APPLICATION_ID_CONTEXT);
    let requestMessage = JSON.stringify(request);
    let responseMessage = JSON.stringify(err);
    let statusCode = err.status;
    let isError = true

    this.backendApplicationService.logRequest(applicationId, processId, requestMessage, responseMessage, statusCode, request.urlWithParams, request.method, isError).subscribe();
  }
}
