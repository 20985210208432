<div>
  <div class="modal-header flex-header">
    <div class="flex-header---filename">{{attachment?.filename}}</div>
    <div class="flex-header-contet"><button type="button" class="btn btn-primary" (click)="bsModalRef.hide()"><span>X</span></button></div>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="pdfContent$ | async as pdf">
      <ngx-extended-pdf-viewer [showOpenFileButton]="false"
                               [showTextEditor]="false"
                               [showDrawEditor]="false"
                               [showStampEditor]="false"
                               [showHandToolButton]="true"
                               [handTool]="false"
                               [textLayer]="true"
                               [filenameForDownload]="attachment?.filename"
                               [src]="pdf">
      </ngx-extended-pdf-viewer>
    </ng-container>
  </div>
  <div class="modal-footer">
  </div>
</div>
