import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'landing-page-cancelled',
  templateUrl: './landing-page-cancelled.component.html',
  styleUrls: ['./landing-page-cancelled.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageCancelledComponent {

}
