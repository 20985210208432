import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../../models/altinn-application/altinn-application';

@Component({
  selector: 'landing-page-queued',
  templateUrl: './landing-page-queued.component.html',
  styleUrls: ['./landing-page-queued.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageQueuedComponent {
  @Input({ required: true }) application!: AltinnApplication;

}
