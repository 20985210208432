import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../models/altinn-application/altinn-application';
import { OAuthService } from 'angular-oauth2-oidc';
import { RedirectionStateParserService } from '../../services/redirection-state-parser.service';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnauthorizedComponent implements OnInit {

  @Input() application!: AltinnApplication;

  constructor(private readonly oauthService: OAuthService,
    private readonly redirectionStateService: RedirectionStateParserService,
  ) {
  }

  ngOnInit(): void {
    if (this.oauthService.hasValidAccessToken())
      this.oauthService.logOut(false, this.redirectionStateService.generateStateString(this.application.applicationID, false, false));
  }
}
