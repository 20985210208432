import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { RedirectionStateParserService } from './redirection-state-parser.service';
import { defer, from, map, switchMap } from 'rxjs';
import { authCodeFlowConfig } from '../auth-config';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(
    private readonly oauthService: OAuthService,
    private readonly redirectionStateService: RedirectionStateParserService
  ) { }

  logOut(applicationId: string) {
    this.oauthService.logOut(false, this.redirectionStateService.generateStateString(applicationId, true, false));
  }

  logInViaPopup() {
    this.oauthService.initLoginFlowInPopup({
      height: 675,
      width: 500,
    });
  }

  logIn(applicationId: string) {
    const state = this.redirectionStateService.generateStateString(applicationId, false, true);
    localStorage.setItem('pre-reditect-state', state);
    this.oauthService.initCodeFlow(state);
  }

  handleUnauthorizedInAltinn(applicationId: string) {
    this.oauthService.logOut(false, this.redirectionStateService.generateStateString(applicationId, false, false));
  }
}
