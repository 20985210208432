import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { IRedirectionState, RedirectionStateParserService } from '../services/redirection-state-parser.service';
import { LandingPageStateService } from '../services/landing-page-state.service';
import { NavigationService } from '../services/navigation.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, defer, filter, finalize, map, of, switchMap, tap } from 'rxjs';
import { authCodeFlowConfig } from '../auth-config';
import { LoadingMaskService } from '../services/loading-mask.service';

export const AuthRedirectionGuard: CanActivateFn = (route, state) => {
  const authService = inject(OAuthService);
  authService.configure(authCodeFlowConfig);

  const router = inject(Router);
  const redirectionStateParserService = inject(RedirectionStateParserService);
  const navService = inject(NavigationService);
  const stateService = inject(LandingPageStateService);
  const maskService = inject(LoadingMaskService);
  const target = router.parseUrl('/application');

  maskService.setManualOverride(true);
  maskService.showLoadingMask(null);
  //state from login needs to be fetched from local storage
  return authService.loadDiscoveryDocumentAndTryLogin()
    .then(() => authService.tryLogin())
    .then(() => authService.hasValidAccessToken())
    .then(result => result ? localStorage.getItem('pre-reditect-state') : null)
    .then(encodedState => encodedState != null ? redirectionStateParserService.decodeStateString(encodedState) : {} as IRedirectionState).catch(() => { return {} as IRedirectionState; })
    .then(state => { localStorage.removeItem('pre-reditect-state'); return state; })
    .then(state => {
      if (state != null) {
        if (state.applicationId) {

          stateService.fetchApplicationByID(state.applicationId);
        }
        if (state.initialLogin) {
          navService.toDetails(true);
        }
        else if (!state.authorizedToSignApplication) {
          navService.toError();
        }
      }
    })
    .finally(() => {
      maskService.setManualOverride(false);
      maskService.turnOffLoadingMask();
    })
    .then(() => {
      return target;
    })
};
