import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RedirectionStateParserService } from '../services/redirection-state-parser.service';
import { NavigationService } from '../services/navigation.service';
import { LandingPageStateService } from '../services/landing-page-state.service';

export const logoutHandlerGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const redirectionStateParserService = inject(RedirectionStateParserService);
  const navService = inject(NavigationService);
  const stateService = inject(LandingPageStateService);

  const stateParam = route.queryParams["state"];
  const target = router.parseUrl('/application');

  //state from logout is returned as querry param
  if (stateParam != null && stateParam != '') {
    try {
      const state = redirectionStateParserService.decodeStateString(stateParam);
      if (state != null) {
        if (state.applicationId) {
          stateService.fetchApplicationByID(state.applicationId);
        }

        if (!state.authorizedToSignApplication) {
          navService.toError();
        }
      }
    } catch (e) {
    }
  }

  return target;
};
