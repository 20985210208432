<ng-container *ngIf="application$ |async as application">
  <ng-container *ngIf="step$ | async as step">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <progress-line [step]="1"></progress-line>
        <div class="landing-page-selector landing-page-container">
          <div [ngSwitch]="application?.status" class="landing-page-selected">
            <div *ngSwitchCase="statusEnum.Error">
              <landing-page-error [application]="application"></landing-page-error>
            </div>
            <div *ngSwitchCase="statusEnum.Canceled">
              <landing-page-cancelled></landing-page-cancelled>
            </div>
            <div *ngSwitchCase="statusEnum.Sent">
              <landing-page-sent [application]="application"></landing-page-sent>
            </div>
            <div *ngSwitchCase="statusEnum.Queued">
              <landing-page-queued [application]="application"></landing-page-queued>
            </div>
            <div *ngSwitchCase="statusEnum.RejectedByDeveloper">
              <landing-page-rejected [application]="application"></landing-page-rejected>
            </div>
            <div *ngSwitchCase="statusEnum.AwaitingSignatureFromDeveloper">
              <landing-page-awaiting-signature [application]="application"></landing-page-awaiting-signature>
            </div>
          </div>
          <div *ngIf="application?.status == statusEnum.AwaitingSignatureFromDeveloper" class="landing-page-nav">
            <button class="btn btn-primary btn-large-font" (click)="toDetails()"><i class="fa fa-solid fa-forward"></i>Neste</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <landing-page-details [application]="application"></landing-page-details>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <landing-page-receipt [application]="application"></landing-page-receipt>
      </ng-container>
      <ng-container *ngSwitchCase="-1">
        <unauthorized [application]="application"></unauthorized>
      </ng-container>
      <ng-container *ngSwitchCaseDefault>
        <landing-page-cancelled></landing-page-cancelled>
      </ng-container>
    </ng-container>
  </ng-container>
  <!--<button class="btn btn-primary btn-large-font" (click)="toUnauthorized()"><i class="fa fa-solid fa-forward"></i>UNAUTHORIZED</button>-->
  <!--<button class="btn btn-primary btn-large-font" (click)="toReceipt()"><i class="fa fa-solid fa-forward"></i>RECEIPT</button>-->
</ng-container>
