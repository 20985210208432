import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingMaskService } from '../../services/loading-mask.service';
import { Observable, tap } from 'rxjs';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingMaskComponent implements OnInit {

  loading$: Observable<boolean>;
  message$: Observable<string|null>;
  @Input() detectRouteTransitions = false;

  constructor(
    private loadingService: LoadingMaskService,
    private router: Router) {
    this.loading$ = this.loadingService.loading$;
    this.message$ = this.loadingService.message$;
  }

  ngOnInit(): void {
    if (this.detectRouteTransitions) {
      this.router.events
        .pipe(
          tap((event) => {
            if (event instanceof RouteConfigLoadStart) {
              this.loadingService.showLoadingMask(null);
            } else if (event instanceof RouteConfigLoadEnd) {
              this.loadingService.turnOffLoadingMask
            }
          })
        )
        .subscribe();
    }
  }

}
