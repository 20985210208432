import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BackendApplicationService } from '../../services/backend/backend-application.service';
import { Observable, map, switchMap, tap } from 'rxjs';
import { AltinnApplication } from '../../models/altinn-application/altinn-application';
import { ApplicationStatus } from '../../models/altinn-application/application-status-enum';
import { AltinnApplicationService, IFormSubmissionResult } from '../../services/altinn/altinn-application.service';
import { NavigationService } from '../../services/navigation.service';
import { LandingPageStateService } from '../../services/landing-page-state.service';
import { AuthorizationService } from '../../services/authorization.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-landing-page-main-container',
  templateUrl: './landing-page-main-container.component.html',
  styleUrls: ['./landing-page-main-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageMainContainerComponent implements OnInit {

  statusEnum = ApplicationStatus;

  step$: Observable<number>;
  application$: Observable<AltinnApplication>

  constructor(
    private readonly backendApplicationService: BackendApplicationService,
    private readonly altinnCommunicationService: AltinnApplicationService,
    private readonly navigationService: NavigationService,
    private readonly landingpageStateService: LandingPageStateService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.application$ = this.landingpageStateService.application$;
    this.step$ = this.navigationService.step$;
  }

  ngOnInit(): void {
    this.landingpageStateService.updateFromRoute(this.activatedRoute);
  }

  toDetails() {
    this.navigationService.toDetails();
  }

  toReceipt() {
    this.navigationService.toReceipt();
  }

  toUnauthorized() {
    this.navigationService.toError();
  }
}
