export interface IAltinnRequestContext {
  applicationProcessId: string;
  applicationId: string;
  neighborNotificationId: string;
  enterpriseId: string;
  applicationType: AltinnApplicationType;
}

export interface IAltinnSubmission {
  type: string;
  serviceCode: number;
  serviceEdition: number;
  _embedded: IAltinnEmbedded;
}

export interface IAltinnEmbeddedForm {
  type: string;
  dataFormatId: string;
  dataFormatVersion: string;
  formData: string;

  FormId?: string;
  Name?: string;
}

export interface IAltinnEmbedded {
  forms: IAltinnEmbeddedForm[];
  attachments: IVedlegg[];
}

export enum AltinnApplicationType {
  CertificateOfCompletion = 5,
  AWE = 13,
}

export interface IAltinnForm {
  formId: string;
  serviceCode: number;
  serviceEditionCode: number;
  dataFormatID: string;
  dataFormatVersion: string;

  name: string;
  availableService: boolean;
  templateUrl: string;
  wikiUrl: string;
  openWikiUrl: string;
  modelUrl: string;
  data: string;
  sendTo: string;
  formType: string;
  status: string;
  sendAsCompany: boolean;
  orgNo: string;
  vedlegg: IVedlegg[];
  skjemaSet: ISkjemaSet[];
}

export interface ISkjemaSet {
  dataFormatId: string;
  dataFormatVersion: string;
  xmlData: string;
  name: string;
}

export interface IVedlegg {
  fileName: string;
  attachmentType: string;
  data: string;
}

export interface IReportAttachment {
  content?: ArrayBuffer; // for binary attachments
  id?: string; // for attachments stored in temporary folder
  blobReferenceId?: string; // for attachments stored in azure
  documentLink?: string; // for attachments stored as url
  blobContainerName?: string; // if not stored in document register container please change this
  filename: string; // display name
  size?: number; // size that should be displayed
  canBeRemoved: boolean; // can this attachment be removed from email
  shouldAttachAsLink?: boolean; // if report is bigger than 20Mb attach as a link
}

export interface IBlobFile {
  formName: string,
  filename: string,
  content: ArrayBuffer,
  isMergedPdf: boolean,
}
