import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../../models/altinn-application/altinn-application';

@Component({
  selector: 'landing-page-rejected',
  templateUrl: './landing-page-rejected.component.html',
  styleUrls: ['./landing-page-rejected.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageRejectedComponent {
  @Input({ required: true }) application!: AltinnApplication;
}
