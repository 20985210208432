<div class="landing-page">
  <div class="top-bar">
    <img src="/assets/holte-as-eg.svg" alt="holte">
  </div>
  <div class="content">
    <router-outlet></router-outlet>
    <loading-mask></loading-mask>
  </div>
</div>

