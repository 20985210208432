import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AltinnApplicationType } from '../../../models/altinn-application/altinn-request-form';

@Component({
  selector: 'landing-page-main-page-header',
  templateUrl: './landing-page-main-page-header.component.html',
  styleUrl: './landing-page-main-page-header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageMainPageHeaderComponent {
  @Input({ required: true }) applicationType!: AltinnApplicationType;

  applicationTypeEnum = AltinnApplicationType;
}
