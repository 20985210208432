import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const postLogoutEndpoint = "post-logout";

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.oidc.authority,
  redirectUri: `${standardizeURL(environment.baseURL)}`,
  clientId: environment.oidc.clientId,
  responseType: 'code',
  scope: 'altinn:instances.read altinn:instances.write altinn:reportees',
  showDebugInformation: true,
  preserveRequestedRoute: true,
  //silentRefreshRedirectUri: `${environment.baseURL}silent-refresh.html`,
  strictDiscoveryDocumentValidation: false,
  postLogoutRedirectUri: `${standardizeURL(environment.baseURL)}/${postLogoutEndpoint}`,
  customQueryParams: {
    acr_values: 'idporten-loa-substantial',
    ui_locales: 'nb'
  },
};

export function standardizeURL(url: string) {
  let result = url;

  if(url.endsWith('/')) {
    result = url.slice(0, -1);
  }

  if (url.startsWith('http://')) {
    result.replace('http://,', 'https://');
  }

  return result;
}

