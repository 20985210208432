import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../models/altinn-application/altinn-application';
import { LandingPageStateService } from '../../services/landing-page-state.service';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { BackendApplicationService } from '../../services/backend/backend-application.service';

@Component({
  selector: 'landing-page-receipt',
  templateUrl: './landing-page-receipt.component.html',
  styleUrls: ['./landing-page-receipt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageReceiptComponent {

  @Input({ required: true }) application!: AltinnApplication;
  @Output() logout = new EventEmitter<boolean>();

  archiveLink$: Observable<string>;
  arCode$: Observable<string>;

  constructor(private readonly landingPageStateService: LandingPageStateService,
    private readonly authorizationService: AuthorizationService,
    private readonly backendApplicationService: BackendApplicationService,
  ) {
    this.archiveLink$ = this.landingPageStateService.archiveLink$;
    this.arCode$ = this.landingPageStateService.arCode$;
  }

  email: string = '';

  private reEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  onEmailChange(event: Event): void {
    const value = (event.target as any).value;
    this.email = value;
  }

  get isValid() {
    let result = this.reEmail.test(this.email);
    return result;
  }

  sendEmailReceipt() {
    this.backendApplicationService.sendReceipt(this.application.applicationID, this.email).subscribe();
  }

  logoutFromAltinn() {
    this.authorizationService.logOut(this.application.applicationID);
  }
}
