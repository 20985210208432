import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth-config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  showDevLinks: boolean;

  constructor(
    private readonly oauthService: OAuthService,
  ) {
    this.showDevLinks = !environment.production;
    'nginject'
  }

  ngOnInit() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocument();
  }

  title = 'Landing Page';
}
