import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFilesize'
})
export class FormatFilesizePipe implements PipeTransform {

  transform(value: number, useSiUnits = true, decimalPrecision = 2): string {

    const thresh = useSiUnits ? 1000 : 1024;

    if (Math.abs(value) < thresh) {
      return value + ' B';
    }

    const units = useSiUnits
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** decimalPrecision;

    do {
      value /= thresh;
      ++u;
    } while (Math.round(Math.abs(value) * r) / r >= thresh && u < units.length - 1);


    return value.toFixed(decimalPrecision) + ' ' + units[u];
  }
}
