import { Component,Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressLineComponent {
  @Input() step!: number;
}
