<div class="landing-page-container">
  <div class="header-container"> Søknad om tiltak uten ansvarsrett</div>
  <div class="content-container">
    <span>
      Du har ikke rettigheter til å signere her for {{application.developerName}}, du er nå logget ut fra Atlinn tilkoblingen.
    </span>
    <span>
      Sjekk dine rettigheter for {{application.developerName}} inn i Altinn.
      Ser her for informasjon om rettigheter:
      <ul>
        <li>
          <a href="https://www.dibk.no/verktoy-og-veivisere/andre-fagomrader/fellestjenester-bygg/slik-gir-du-rettigheter-til-byggesak-i--altinn/">Slik gir du rettigheter til plan- og byggesak i Altinn </a>.
        </li>
        <li>
          <a href="https://info.altinn.no/hjelp/profil/enkelttjenester-og-roller/">Altinn - Enkelttjenester og roller</a>
        </li>
      </ul>
    </span>
    <span>
      Ta kontakt med {{application.contactPersonName}} av {{application.contactCompanyName}} for å gjøre nødvendig endringer.<br />
      Tlf: {{application.contactPhone}} Mob: {{application.contactMobile}} Epost: {{application.contactEmail}}
    </span>
  </div>
</div>
