import variables from './variables.json';

export const environment = {
  production: true,
  baseURL: "https://smartaltinnbat.holte.no",
  apiUrl: "https://smartaltinnbackendbat.holte.no",
  altinnBaseUrl: variables.AltinnBaseURL,
  altinnApiKey: variables.AltinnApiKey,
  oidc: {
    authority: "https://test.idporten.no",
    clientId: "e63a243a-730d-4cc1-9034-01568ca42113",
  },
  name: "BAT",
};
