<div>
  <div class="header-container">
    Søknaden kan ikke signeres
  </div>
  <div class="content-container ">
    <span>Byggesøknaden for {{application.address | formatAddress}} blir avvist{{application.rejectionDate! | date: 'yyyy-MM-dd HH:mm'}}. </span>
    <span>Denne lenken er ikke lenger gyldig fordi byggesøknaden ble avvist av tiltakshaver selv.</span>
    <span>Oppgitt årsak:</span>
    <span>{{application.rejectionReason}}</span>
  </div>
</div>
