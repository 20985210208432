import { Component,Input,ViewEncapsulation } from '@angular/core';
import { AltinnApplication } from '../../../models/altinn-application/altinn-application';

@Component({
  selector: 'landing-page-error',
  templateUrl: './landing-page-error.component.html',
  styleUrls: ['./landing-page-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageErrorComponent {
  @Input({ required: true }) application!: AltinnApplication;

}
