import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, filter, map, merge, switchMap, tap } from 'rxjs';
import { AltinnApplication } from '../models/altinn-application/altinn-application';
import { BackendApplicationService } from './backend/backend-application.service';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LandingPageStateService {

  private readonly applicationIdSubject = new BehaviorSubject<string>('');
  private readonly applicationOverrideSubject = new BehaviorSubject<AltinnApplication>({} as AltinnApplication);
  private readonly arCodeSubject = new BehaviorSubject<string>('');
  private readonly messageIdSubject = new BehaviorSubject<string>('');

  application$: Observable<AltinnApplication>;
  arCode$: Observable<string>;
  archiveLink$: Observable<string>;
  constructor(
    private readonly backendApplicationService: BackendApplicationService,
  ) {
    const overrideSource = this.applicationOverrideSubject.asObservable().pipe(
      filter(application => application != null && application.applicationID != null),
    );

    const idOverrideSource = this.applicationIdSubject.asObservable().pipe(
      filter(id => !!id && id != ''),
      switchMap(id => this.backendApplicationService.getApplication(id!))
    )

    this.application$ = merge(idOverrideSource, overrideSource);
    this.arCode$ = this.arCodeSubject.asObservable();
    this.archiveLink$ = this.messageIdSubject.asObservable().pipe(
      map(messageId => messageId.startsWith('b') || messageId.startsWith('a') ? messageId.substring(1) : messageId),
      map(messageId => `${environment.altinnBaseUrl}Pages/ServiceEngine/Receipt/Receipt.aspx?RAReporteeElementID=${messageId}`),
    );
  }

  //allows for updating application state without reloading it from the backend
  setState(application: AltinnApplication) {
    this.applicationOverrideSubject.next(application);
  }

  //always loads the data from the backend, even if you pass the current application Id
  fetchApplicationByID(applicationId: string) {
    this.applicationIdSubject.next(applicationId);
  }

  updateAltinnApplionReference(arCode: string, messageId: string) {
    this.arCodeSubject.next(arCode);
    this.messageIdSubject.next(messageId);
  }

  updateFromRoute(route: ActivatedRoute) {
    const idFromRoute = route.snapshot.queryParamMap.get("id");
    if (idFromRoute && idFromRoute != '' && idFromRoute !== this.applicationIdSubject.value) {
      this.applicationIdSubject.next(idFromRoute);
    }
  }
}
