import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';


export enum LandingPageStep {
  Start = 1,
  Details = 2,
  Receipt = 3,
  Error = -1,
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private stepSubject = new BehaviorSubject<number>(1);
  private postRedirectSubject = new BehaviorSubject<boolean>(false);

  step$: Observable<number>;
  isPostRedirect$: Observable<boolean>;

  constructor() {
    this.isPostRedirect$ = this.postRedirectSubject.asObservable();
    this.step$ = this.stepSubject.asObservable();
  }

  back() {
    this.stepSubject.next(this.clampStep(this.stepSubject.value - 1));
  }

  next() {
    this.stepSubject.next(this.clampStep(this.stepSubject.value + 1));
  }

  toStart() {
    this.stepSubject.next(1);
  }

  //error page can only be reached by explicit action to navigate
  toError() {
    this.stepSubject.next(-1);
  }

  toDetails(isPostRedirect: boolean = false) {
    this.stepSubject.next(2);
    this.postRedirectSubject.next(isPostRedirect);
  }

  toReceipt() {
    this.stepSubject.next(3);
  }

  private clampStep(step: number) {
    return Math.min(Math.max(step, 3), 1)
  }

}
