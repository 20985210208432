import { Component, Input, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { ApplicationAttachment } from '../../models/altinn-application/application-attachment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BackendApplicationService } from '../../services/backend/backend-application.service';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { LandingPageStateService } from '../../services/landing-page-state.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pdf-view-modal',
  templateUrl: './pdf-view-modal.component.html',
  styleUrl: './pdf-view-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PdfViewModalComponent {
  attachment?: ApplicationAttachment;
  pdfContent$: Observable<Blob>;

  constructor(
    public bsModalRef: BsModalRef,
    readonly backendService: BackendApplicationService,
    readonly stateService: LandingPageStateService,
    readonly domSanitizer: DomSanitizer,
  ) {
    this.pdfContent$ = stateService.application$.pipe(
      map(application => application.applicationID),
      switchMap(applicationID => backendService.fetchAttachment(applicationID, this.attachment!.blobReferenceId)),
      filter(blob => blob != null),
    );

  }



}
