import { Injectable } from '@angular/core';
import { BackendApplicationService } from '../backend/backend-application.service';
import { AltinnApplicationType, IAltinnEmbeddedForm, IAltinnForm, IAltinnRequestContext, IAltinnSubmission, IVedlegg } from '../../models/altinn-application/altinn-request-form';
import { map, of, catchError, concatMap, concat, finalize } from 'rxjs';
import { AltinnRequestService } from './altinn-request.service';
import { HttpResponse } from '@angular/common/http';
import { LoadingMaskService } from '../loading-mask.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AltinnApplication } from '../../models/altinn-application/altinn-application';


export interface IRequestContext {
  readonly orgNo: string,
  readonly data: IAltinnSubmission,
  readonly loggingContext: IAltinnRequestContext,
  readonly attachments: IVedlegg[];
  readonly privatePersonApplication: boolean;

  firstMessageId: string,
  currentMessageId: string,
  ARCode: string,
  statusUpdated: boolean;

}

export interface IFormSubmissionResult {
  success: boolean;
  arCode: string;
  messageId: string;
  unauthorizedInAltinn: boolean;
}

export class UnautorizedInAltinnError extends Error {

}

@Injectable({
  providedIn: 'root'
})
export class AltinnApplicationService {

  private readonly emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    private readonly backendApplicationService: BackendApplicationService,
    private readonly altinnRequestService: AltinnRequestService,
    private readonly loadingMaskService: LoadingMaskService,
    private readonly oauthService: OAuthService,
  ) {
  }

  public sendAltinnForm(application: AltinnApplication) {
    this.loadingMaskService.showLoadingMask(null);
    this.loadingMaskService.setManualOverride(true);

    return this.backendApplicationService.getXML(application.applicationID).pipe(
      map(formData => this.mapFormToRequest(formData.form, application)),
      concatMap((request: IRequestContext) => this.submitApplication(request, application)),
      concatMap((request: IRequestContext) => this.submitAttachments(request, application)),
      concatMap((request: IRequestContext) => this.submitFinalPackage(request, application)),
      concatMap((request: IRequestContext) => this.getSubmittedApplication(request, application)),
      concatMap((request: IRequestContext) => this.sendAcceptApplicationCommand(request, application)),
      concatMap((request: IRequestContext) => this.fetchAndUploadFullApplicationPDF(request, application)),
      concatMap((request: IRequestContext) => this.fetchAndUploadIndividualAttachments(request, application)),
      map((request: IRequestContext) => { return { arCode: request.ARCode, messageId: request.currentMessageId, success: true } as IFormSubmissionResult }),
      catchError((err: Error) => { return of({ arCode: '', messageId: '', success: false, unauthorizedInAltinn: err instanceof UnautorizedInAltinnError } as IFormSubmissionResult); }),
      finalize(() => {
        this.loadingMaskService.setManualOverride(false);
        this.loadingMaskService.turnOffLoadingMask();
      }),
    );
  }

  public fetchFile(messageId: string, application: AltinnApplication) {
    return this.backendApplicationService.getXML(application.applicationID).pipe(
      map(formData => this.mapFormToRequest(formData.form, application)),
      map(request => {
        request.currentMessageId = messageId;
        return request;
      }),
      concatMap((request: IRequestContext) => this.fetchAndUploadFullApplicationPDF(request, application)),
    );
  }

  private mapFormToRequest(form: IAltinnForm, application: AltinnApplication): IRequestContext {
    form.skjemaSet = form.skjemaSet.filter(f => f.dataFormatId != '6146');
    form = form.sendAsCompany ? form : this.injectBirthno(form)
    const altinnForms: IAltinnEmbeddedForm[] = [
      {
        type: "MainForm",
        dataFormatId: form.dataFormatID,
        dataFormatVersion: form.dataFormatVersion,
        formData: form.data,
      },
    ];

    for (let subForm of form.skjemaSet) {
      altinnForms.push({
        type: "SubForm",
        dataFormatId: subForm.dataFormatId,
        dataFormatVersion: subForm.dataFormatVersion,
        formData: subForm.xmlData,
      });
    }

    const altinnData: IAltinnSubmission = {
      type: "FormTask",
      serviceCode: form.serviceCode,
      serviceEdition: form.serviceEditionCode,
      _embedded: {
        forms: altinnForms,
        attachments: [],
      },
    };

    const altinnRequestContext: IAltinnRequestContext = {
      applicationProcessId: application.applicationProcessID,
      applicationId: application.applicationID,
      enterpriseId: this.emptyGuid,
      neighborNotificationId: this.emptyGuid,
      applicationType: application.applicationType,
    };

    return {
      orgNo: form.orgNo,
      data: altinnData,
      loggingContext: altinnRequestContext,
      attachments: form.vedlegg,
      ARCode: '',
      currentMessageId: '',
      firstMessageId: '',
      privatePersonApplication: !form.sendAsCompany,
    } as IRequestContext;
  }

  private injectBirthno(form: IAltinnForm) {

    const claims = this.oauthService.getIdentityClaims();
    const birthNo = claims['pid'];

    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const doc = parser.parseFromString(form.data, "text/xml");

    const element = doc.querySelector("Ferdigattest>tiltakshaver>foedselsnummer, TiltakUtenAnsvarsrett>tiltakshaver>foedselsnummer")!;
    for (const attributeName of element.getAttributeNames()) {
      element.removeAttribute(attributeName);
    }

    element.appendChild(doc.createTextNode(birthNo));

    const result = serializer.serializeToString(doc);
    form.data = result;
    return form;
  }

  private handleError(context: IRequestContext, applicationId: string, processId: string) {
    const rollbackObservables = [];

    if (context.firstMessageId != null) {
      var rollbackInAltinn = this.altinnRequestService.deleteMessage(context.orgNo, context.firstMessageId, applicationId, processId);
      rollbackObservables.push(rollbackInAltinn);
    }

    return of(concat(...rollbackObservables).subscribe());
  }

  private getMessageIdFromReponse<T>(response: HttpResponse<T>) {
    const uri = response.headers.get("Location");
    if (!uri) {
      throw Error("Error in Altinn response");
    }

    const index = uri.lastIndexOf("/") + 1;
    const messageId = uri.substring(index, uri.length);
    return messageId;
  }

  private submitFinalPackage(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.submitFinalPackage(this.getMessageBox(request), request.firstMessageId, request.data.serviceCode, request.data.serviceEdition, application.applicationID, application.applicationProcessID)
      .pipe(
        catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw err; }))),
        map(response => {
          request.currentMessageId = this.getMessageIdFromReponse(response);
          return request;
        }),
      );
  }

  private submitAttachments(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.submitAttachments(request.attachments, this.getMessageBox(request), request.firstMessageId).pipe(
      catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw err; }))),
      map(_ => request),
    );
  }

  private submitApplication(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.submitApplication(this.getMessageBox(request), request.data, application.applicationID, application.applicationProcessID).pipe(
      catchError(err => {
        if (err.status === 401 && err.statusText === 'Invalid reportee, verify that you have the necessary rights.') {
          throw new UnautorizedInAltinnError;
        }

        throw err;
      }),
      map(response => {
        request.firstMessageId = this.getMessageIdFromReponse(response);
        return request;
      }),
    );
  }

  private getSubmittedApplication(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.getSubmittedApplication(this.getMessageBox(request), request.currentMessageId).pipe(
      catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw Error("Rollback"); }))),
      map(response => {
        const archiveReference = response.ArchiveReference as string;
        const messageId = response.MessageId as string;
        request.currentMessageId = messageId;
        request.ARCode = archiveReference;
        return request;
      }),
    );
  }

  private sendAcceptApplicationCommand(request: IRequestContext, application: AltinnApplication) {
    return this.backendApplicationService.acceptApplication(application.applicationID, application.applicationProcessID, request.ARCode, request.currentMessageId).pipe(
      catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw Error("Rollback"); }))),
      map(_ => {
        request.statusUpdated = true;
        return request;
      }),
    );
  }

  private fetchAndUploadFullApplicationPDF(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.fetchFullApplicationPDF(this.getMessageBox(request), request.currentMessageId)
      .pipe(
        concatMap(attachment => {
          return this.backendApplicationService.saveAttachment(application.applicationID, attachment);
        }),
        catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw Error("Rollback"); }))),
        map(_ => request),
      );
  }

  private fetchAndUploadIndividualAttachments(request: IRequestContext, application: AltinnApplication) {
    return this.altinnRequestService.fetchIndividualApplicationAttachments(this.getMessageBox(request), request.currentMessageId).
      pipe(
        concatMap(attachment => {
          return this.backendApplicationService.saveAttachment(application.applicationID, attachment).pipe(map(_ => request));
        }),
        catchError(err => this.handleError(request, application.applicationID, application.applicationProcessID).pipe(map(_ => { throw Error("Rollback"); }))),
      );
  }

  private getMessageBox(request: IRequestContext) {
    return request.privatePersonApplication ? 'my' : request.orgNo;
  }
}
