import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ILoadingContext {
  skipLoading: boolean;
  message: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingMaskService {

  private manualOverride = false;
  private loadingMaskSubject = new BehaviorSubject<boolean>(false);
  private messageSubject = new BehaviorSubject<string | null>(null);

  loading$ = this.loadingMaskSubject.asObservable();
  message$ = this.messageSubject.asObservable();

  showLoadingMask(message: string | null) {
    if (!this.manualOverride) {
      this.loadingMaskSubject.next(true);
      this.messageSubject.next(message);
    }
  }

  updateMessage(message: string) {
    this.messageSubject.next(message);
  }

  clearMessage() {
    this.messageSubject.next(null);
  }

  turnOffLoadingMask() {
    if (!this.manualOverride) {
        this.loadingMaskSubject.next(false);
    }
  }

  //prevents changing of service state
  //use before and after long running processes that trigger many requests
  setManualOverride(value: boolean) {
    this.manualOverride = value;
  }
}
