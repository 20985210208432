<div>
  <landing-page-main-page-header [applicationType]="application.applicationType"></landing-page-main-page-header>
  <div class="content-container ">
    <p>Søknaden er klar for signering og innsending til kommunen.</p>
    <p>
      På grunn av tiltakets art, kan denne søknaden forestås av deg som tiltakshaver, uten bruk av ansvarlige foretak.
      Som tiltakshaver har du derfor hovedansvaret for det som skal bygges og ansvaret for at kravene i pbl. § 28-1 med tilhørende bestemmelser i TEK17 er oppfylt.
      Du er også selv ansvarlig for å vurdere krav til sikkerhet og ev. innhente fagkyndig bistand.
    </p>
    <p>Forsikre deg om at du har lest alle dokumentene før du godkjenner søknaden.</p>
  </div>
</div>
