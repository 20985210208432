<div>
  <div class="header-container">
    Søknad om tiltak uten ansvarsrett
  </div>
  <div class="content-container">
    <span>Du har allerede sendt inn byggesøknaden for {{application.address | formatAddress}} og denne er bekreftet levert.</span>
    <span>Søknaden ble sendt {{application.sendDate | date: 'yyyy.MM.dd HH:mm'}}.</span>
    <span>Søknaden med vedlegg finner du i ditt arkiv i Altinn, med referanse {{application.arCode}}.</span>
  </div>
</div>
