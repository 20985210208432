import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AltinnApplication, IAltinnFormDTO } from '../../models/altinn-application/altinn-application';
import { IBlobFile } from '../../models/altinn-application/altinn-request-form';
import { ILoadingContext, LoadingOptions } from '../interceptors/loading.interceptor';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class BackendApplicationService {

  private readonly endpointApplicationPath = "api/application"

  constructor(
    private readonly httpClient: HttpClient,
    private readonly oauthService: OAuthService,
  ) {
  }

  sendReceipt(applicationId: string, email: string) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/receipt`;
    return this.httpClient.post(endpoint, {
      email: email
    }, {
      headers: this.buildAuthorizationHeaders(),
    });
  }

  getApplication(applicationId: string) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}`;
    return this.httpClient.get<AltinnApplication>(endpoint);
  }

  rejectApplication(applicationId: string, rejectionReason: string) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/reject`
    return this.httpClient.put(endpoint, {
      rejectionReason: rejectionReason,
    });
  }

  acceptApplication(applicationId: string, applicationProcessId: string, arCode: string, messageId: string,) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/accept`
    return this.httpClient.put(endpoint, {
      applicationId: applicationId,
      applicationProcessId: applicationProcessId,
      altinnARCode: arCode,
      altinnMessageId: messageId,
      description: '',
    }, {
      headers: this.buildAuthorizationHeaders(),
    });
  }

  fetchAttachment(applicationId: string, blobReferenceId: string) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/attachment/${blobReferenceId}`
    return this.httpClient.get<Blob>(endpoint, { responseType: 'blob' as 'json' })
  }

  getXML(applicationId: string): Observable<IAltinnFormDTO> {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/xml`
    return this.httpClient.get<IAltinnFormDTO>(endpoint, { headers: this.buildAuthorizationHeaders(), });
  }

  getXMLDraft(applicationId: string) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/xml/draft`
    return this.httpClient.get<IAltinnFormDTO>(endpoint, { headers: this.buildAuthorizationHeaders(), });
  }

  saveAttachment(applicationId: string, file: IBlobFile) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/attachment`
    const formData: FormData = new FormData();
    const blob = new Blob([file.content]);
    formData.append('file', blob, file.filename);

    return this.httpClient.post(endpoint, formData,
      {
        params: {
          mergedPDF: file.isMergedPdf,
          formName: file.formName
        },
        headers: this.buildAuthorizationHeaders(),
      });
  }

  logRequest(applicationId: string, processId: string, request: string, response: string, statusCode: number, requestUrl: string, method: string, isError: boolean) {
    const endpoint = `${environment.apiUrl}/${this.endpointApplicationPath}/${applicationId}/log`;

    const data = {
      applicationId: applicationId,
      applicationProcessId: processId,
      request: request,
      response: response,
      statusCode: statusCode,
      url: requestUrl,
      method: method,
      isError: isError,
    };
    const context = new HttpContext();
    context.set(LoadingOptions, { skipLoading: true, message: null } as ILoadingContext);
    return this.httpClient.post(endpoint, data, { context: context });
  }

  private buildAuthorizationHeaders() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
    });
    return headers;
  }
}
